import { isServer } from '../browser-detection';

export const isExternal = url => {
  return /^((tel:)|((f|ht)tps?:\/\/))/i.test(url);
};

export const linkSplit = (href = '') => {
  href = href.toString();
  let parser;
  let altUrl;

  if (!isServer()) {
    altUrl = window?.location?.origin + href;
  } else {
    altUrl = 'https://' + global.reqHost + href;
  }

  try {
    parser = new URL(href);
  } catch {
    parser = new URL(altUrl);
  }

  let data = {
    appendTo: value => {
      if (!value) return;
      const slash = /(\/|-)$/g.test(parser.pathname) ? '' : '/';
      parser.pathname = '' + parser.pathname + slash + value;
    },
    getPathOnlyUrl: () => {
      return [parser.pathname, parser.hash, parser.search].join('');
    }
  };

  return Object.assign(parser, data);
};
