'use strict';

import React from 'react';

//import './progress-circle.scss';

export default function ProgressCircle({ ariaLabelText }) {
  return (
    <ajax-page-spinner>
      <progress-circle
        class="progress-indeterminate"
        role="progressbar"
        aria-label={ariaLabelText}
      />
    </ajax-page-spinner>
  );
}
